<template>
  <div class="idea">
    <head-nav></head-nav>
    <about-img></about-img>
    <div class="home-idea">
      <h3 class="home-idea-title"><img
          :src="$t('about.title-6')"
          alt=""
          :style="this.$i18n.locale==='en'?'width:300px;height:auto;':''"
        /></h3>
      <div class="lists">
        <div
          class="list"
          v-for="(item, index) in idea"
          :key="index"
        >
          <div class="list-left">
            <span class="list-nums">{{ (index + 1).toString().padStart(2, '0')  }}</span>
            <div class="list-img">
              <img
                class="list-imgs"
                :src="item.image.publicUrl"
                alt=""
              />
            </div>
          </div>

          <div class="list-right">
            <h4 class="list-title">{{ item.title }}</h4>
            <p class="content">{{item.subtitle[0] }}</p>
            <p class="contents">{{item.subtitle[1] }}</p>
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>

</template>

<script>
import gql from 'graphql-tag';

import HeadNav from './head-nav';
import AboutImg from './about-img';
import Footers from './footers';

export default {
  components: {
    HeadNav,
    AboutImg,
    Footers,
  },
  apollo: {
    idea: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              idea: allHomePageChessboards {
                title
                subtitle
                image {
                  publicUrl
                }
              }
            }
          `;
        }
        return gql`
          query {
            idea: allEnHomePageChessboards {
              title
              subtitle
              image {
                publicUrl
              }
            }
          }
        `;
      },
      update(value) {
        const newList = value.idea.map((item) => ({
          ...item,
          subtitle: item.subtitle.split('\n'),
        }));
        return newList;
      },
    },
  },
  data() {
    return {
      idea: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.home-idea {
  margin: 0 30px;
  padding-bottom: 50px;

  .home-idea-title {
    text-align: center;
    width: 100%;
    height: 140px;
    text-align: center;
    padding: 50px 0 30px 0;

    img {
      height: 64px;
    }
  }

  .lists {
    width: 100%;

    .list {
      width: 100%;
      height: 200px;
      border: 1px solid #eee;
      border-radius: 10px;
      display: flex;
      margin-bottom: 20px;
      overflow: hidden;

      &:nth-child(2n + 2) {
        background: #666666;

        .list-right {
          color: #fff;
        }
      }

      .list-left {
        width: 300px;
        position: relative;

        .list-nums {
          display: inline-block;
          width: 40px;
          height: 40px;
          line-height: 40px;
          background: #ffbf00;
          text-align: center;
          font-size: 20px;
          border-radius: 50%;
          position: absolute;
          top: 20px;
          left: 30px;
        }

        .list-img {
          position: absolute;
          top: 40px;
          left: 90px;
          width: 110px;
          height: 110px;

          .list-imgs {
            width: 100%;
            height: 100%;
          }
        }
      }

      .list-right {
        width: 460px;
        margin-right: 50px;

        .list-title {
          padding: 15px 0;
          font-size: 40px;
          font-weight: 600;
          border-bottom: 1px solid #eee;
        }

        p {
          font-size: 18px;
          margin-top: 15px;
        }
      }
    }
  }
}
</style>
